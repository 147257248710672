.MyLogin-button {
  margin: auto;
  margin-top: 45px;
  font-weight: bold;
  padding: 12px 24px;

  &:hover {
    background-color: #c71c26 !important;
  }
}

.login-input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  label {
    font-family: 'cera-pro-medium';
  }
}

.login-error-message {
  font-size: 12px;
  color: #c71c26;
  margin: auto;
  margin-bottom: 0px;
  width: 100%;
  font-family: 'cera-pro-light';
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 45px;
}
