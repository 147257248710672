.button-logout-mobile {
  margin-bottom: 50px;
  margin-left: auto;
  display: none;
  text-transform: uppercase;
  font-size: 8px !important;

  @media screen and (max-width: 767px) {
    display: inline;
  }

  &:hover {
    background-color: #c71c26 !important;
  }
}

.button-logout-desktop {
  margin-bottom: 50px;
  margin-left: auto;
  display: none;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  top: 0;
  margin: 40px 50px;

  @media screen and (min-width: 767px) {
    display: inline;
  }

  &:hover {
    background-color: #c71c26 !important;
  }
}
