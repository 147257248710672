.code-form {
  width: 50%;
  margin: auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.myEnterCode__container {
  width: 85%;
  margin: auto;

  label {
    font-family: 'cera-pro-medium';
  }
}

.input {
  width: 85%;
  margin: auto;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  font-family: 'cera-pro-black';

  @media screen and (max-width: 767px) {
    width: 65%;
  }
}

.button-validate {
  margin-top: 32px;

  &:hover {
    background-color: #c71c26 !important;
  }
}

.error-message {
  font-size: 12px;
  color: #c71c26;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  width: 85%;
  font-family: 'cera-pro-light';
}

.loader-wrapper-code {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 32px;
}
