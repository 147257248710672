.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;

    .popup-title {
      font-size: 30px;
      font-weight: 900;
      margin-bottom: 0px;
      font-family: 'cera-pro-black';
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .popup-buttons-container {
      display: flex;
      justify-content: space-between;

      .button-retour {
        margin: auto;
        margin-top: 45px;
        font-weight: bold;
        padding: 12px 54px;
        width: 45%;

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 14px;
        }

        &:hover {
          background-color: #b1b1b1 !important;
        }
      }
      .button-valider {
        margin: auto;
        margin-top: 45px;
        font-weight: bold;
        padding: 12px 24px;
        width: 45%;

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 12px;
        }

        &:hover {
          background-color: #c71c26 !important;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      margin: 15px;
    }
  }
}
