@font-face {
  font-family: 'cera-pro-black-italic';
  src: url('./assets/typography/font-cera/cera-pro-black-italic.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-black';
  src: url('./assets/typography/font-cera/cera-pro-black.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-bold-italic';
  src: url('./assets/typography/font-cera/cera-pro-bold-italic.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-bold';
  src: url('./assets/typography/font-cera/cera-pro-bold.otf') format('truetype');
}
@font-face {
  font-family: 'cera-pro-light-italic';
  src: url('./assets/typography/font-cera/cera-pro-light-italic.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-light';
  src: url('./assets/typography/font-cera/cera-pro-light.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-medium-italic';
  src: url('./assets/typography/font-cera/cera-pro-medium-italic.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-medium';
  src: url('./assets/typography/font-cera/cera-pro-medium.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-regular-italic';
  src: url('./assets/typography/font-cera/cera-pro-regular-italic.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-regular';
  src: url('./assets/typography/font-cera/cera-pro-regular.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-thin-italic';
  src: url('./assets/typography/font-cera/cera-pro-thin-italic.otf')
    format('truetype');
}
@font-face {
  font-family: 'cera-pro-thin';
  src: url('./assets/typography/font-cera/cera-pro-thin.otf') format('truetype');
}

@import-normalize;

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: 'cera-pro-regular';
}

body {
  margin: 0;
  font-family: 'cera-pro-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: 'cera-pro-regular';
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

img {
  background-color: transparent;
}

/* App styles */
.app-wrapper {
  height: 100%;
  width: 100%;
  padding: 75px;
  background-color: #00928b;

  /* Mobile */
  @media screen and (max-width: 767px) {
    padding: 25px;
    position: relative;
    overflow: hidden;
  }

  @media screen and (max-width: 1000px) and (orientation: landscape) {
    padding: 40px;
    min-height: 100%;
    height: unset;
  }

  @media screen and (max-height: 800px) and (orientation: portrait) {
    padding-bottom: 765px;
  }
}
.app-background {
  background-color: white;
  position: relative;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: 1000px) and (orientation: landscape) {
    margin-bottom: 250px;
  }

  @media screen and (min-width: 768px) and (orientation: landscape) {
    margin-bottom: 0px;
  }

  /* Snow */
  .snow-1 {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 15%;
    left: 30%;
    z-index: 1;
  }
  .snow-2 {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 40%;
    left: 15%;
    z-index: 1;
  }
  .snow-3 {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 30%;
    left: 75%;
    z-index: 1;
  }

  /* Tree */
  .tree-1 {
    width: 350px;
    height: 350px;
    position: absolute;
    bottom: -1%;
    right: 5%;
    z-index: 1;
  }

  /* Animal */
  .animal-1 {
    width: 350px;
    height: 350px;
    position: absolute;
    bottom: -3%;
    left: 5%;
    z-index: 1;
  }

  & .MyLogin {
    /* width: 500px; */

    & .MyLogin-logo {
      width: 100px;
      height: 100px;
    }
  }

  /* Tablet */
  @media screen and (max-width: 1000px) {
    .snow-1,
    .snow-2,
    .snow-3 {
      display: none;
    }

    .tree-1 {
      width: 325px;
      height: 325px;
      position: absolute;
      right: -5%;
    }

    .animal-1 {
      width: 325px;
      height: 325px;
      position: absolute;
      left: -3%;
    }

    & {
      align-items: flex-start;
      padding-top: 100px;
    }
  }

  /* Mobile */
  @media screen and (max-width: 767px) {
    .snow-1,
    .snow-2,
    .snow-3 {
      display: none;
    }

    .tree-1 {
      width: 200px;
      height: 200px;
      position: absolute;
      right: -5%;
    }

    .animal-1 {
      width: 200px;
      height: 200px;
      position: absolute;
      left: -3%;
    }

    & {
      align-items: flex-start;
      padding-top: 0px;
      height: auto;
      position: unset;
      overflow: unset;
    }

    & .app-root {
      width: 100%;
      padding: 40px 50px;
      border-radius: 10px;

      & .MyLogin {
        width: 100%;

        & .MyLogin-logo {
          width: 75px;
          height: 75px;
        }
        & .MyLogin-button {
          width: 100%;
        }
      }
    }
  }
}

.app-root {
  z-index: 1000;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;

  @media screen and (max-width: 1000px) and (orientation: landscape) {
    padding: 40px;
    min-height: 100%;
    height: unset;
  }

  @media screen and (min-width: 768px) and (orientation: landscape) {
    padding: 40px;
    min-height: 100%;
    /* margin-bottom: 400px; */
    margin-bottom: 100px;
    height: unset;
  }
}

.MyLogin * {
  background-color: transparent;
}
