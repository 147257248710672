.formatted-date {
  font-size: 15px;
}

.lego-logo {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  margin-bottom: 65px;

  @media screen and (max-width: 1000px) {
    width: 75px;
    height: 75px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 32px;
  }
}
.code-info {
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
  }

  h3 {
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    margin-bottom: 0px;
    font-family: 'cera-pro-black';
    text-transform: uppercase;

    @media screen and (max-width: 1000px) {
      font-size: 24px;
    }
  }
  p {
    text-align: center;
    font-family: 'cera-pro-light';

    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0.5rem;
    margin-top: 5px;

    @media screen and (max-width: 767px) {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
    }
  }
}

.customer-info {
  text-align: center;

  .name {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0px;
    font-family: 'cera-pro-medium';

    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
  .email {
    text-align: center;
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 8px;
    font-family: 'cera-pro-light';

    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }
  .telephone {
    text-align: center;
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 8px;
    font-family: 'cera-pro-light';

    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: space-between;

  .button-retour {
    margin: auto;
    margin-top: 45px;
    font-weight: bold;
    padding: 12px 54px;
    width: 45%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 24px;
    }

    &:hover {
      background-color: #b1b1b1 !important;
    }
  }
  .button-valider {
    margin: auto;
    margin-top: 45px;
    font-weight: bold;
    padding: 12px 24px;
    width: 45%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 12px;
    }

    &:hover {
      background-color: #c71c26 !important;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
}

/* Popup */
